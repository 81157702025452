@charset "UTF-8";

@import "./_setting";
@import "./_mixin";
@import "./module/_btn";
@import "./module/_title";
@import "./module/_other";

.l-main {
  overflow: hidden;
}

// トップスライダー 設定
.l-top-mainvisual {
  .slider {
    li {
      position: relative;
      .slider-textArea {
        position: absolute;
        bottom: 30px;
        right: 30px;
        text-align: center;
        max-width: 395px;
        width: 100%;
        min-height: 80px;
        text-decoration: none;
        z-index: 2;
        @include bpTb {
          bottom: 60px;
          right: 50%;
          transform: translateX(50%);
        }
        @include bpSp {
          max-width: 310px;
          width: 100%;
        }
        .slider-text {
          display: flex;
          text-decoration: none;

          @include bpSp {
            justify-content: center;
          }

          .title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 33px 16px;
            background-color: #0a6f3a;
            color: #fff;
            letter-spacing: 0.06em;
            @include bpSp {
              padding: 23px 10px;
              max-width: 80px;
              width: 100%;
              @include f-size(13);
            }
          }
          .text--content {
            padding: 15px 56px 15px 16px;
            background-color: #fff;
            @include bpTb {
              max-width: 270px;
              width: 100%;
            }
            @include bpSp {
              max-width: 220px;
              @include f-size(13);
              padding: 12px 36px 12px 16px;
            }

            .details {
              position: relative;
              color: #0a6f3a;
              // width: 200px;
              display: flex;
              align-items: center;
              height: 100%;
              text-align: left;
              @include bpSp {
                @include f-size(13);
              }
              &:before,
              &:after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
              }
  
              &:before {
                content: '\f105';
                right: -26px;
                font-family: "Font Awesome 5 Free", sans-serif;
                font-weight: 900;
                color: #fff;
                z-index: 1;
                font: normal normal normal 14px/1 FontAwesome;
                @include bpSp {
                  // top: 46%;
                  right: -20px;
                }
              }
  
              &:after {
                content: "";
                right: -34px;
                background-color: #0a6f3a;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                @include bpSp {
                  // top: 45%;
                  right: -28px;
                }
              }
            }
          }
        }
      }
    }
  }
  // slick 追加設定
  .slick-dotted {
    z-index: 2;
    &.slick-slider {
      margin-bottom: 0;
    }
  }
  .slick-slide {
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 174px;
      width: 100%;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, .35) 10%, rgba(0, 0, 0, 0) 90%);
      z-index: 1;
    }

    img {
      position: relative;
      object-fit: cover;
      // min-width: 1000px;
      width: 100%;
      // min-height: 500px;
      height: 500px;

      @include bpTb {
        // min-width: auto;
      }
    }
  }
  .slick-dots {
    position: absolute;
    left: 28%;
    left: calc( 50% - 25vw);
    transform: translateX(-50%);
    bottom: 30px;
    max-width: 100%;
    width: 100%;
    @include breakpoint(1399px) {
      // left: 17%;
      left: calc( 50% - 32vw);
    }
    @include bpTb {
      left: 50%;
    }
    li {
      width: 15px;
      height: 15px;
      margin: 0 10px;
      border: 1px solid #cfcfcf;
      border-radius: 50%;
      background-color: #fff;
      button {
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before {
          content: "";
          line-height: 15px;
          font-family: 'slick';
          font-size: 6px;
          line-height: 20px;
          color: black;
          display: inline-block;
          top: unset;
          left: unset;
          content: "";
          width: 6px;
          height: 6px;
          background-color: #0a6f3a;
          border-radius: 50%;
          opacity: 0;
          position: relative;
        }
      }
    }

    .slick-active {
      width: 16px;
      height: 16px;
      button {
        &::before {
          opacity: 1;
        }
      }
    }
  }
}


// japanArt
.top-japanArt {
  background-image: url(../img/top/bg-japanArt.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 435px;

  @include bpSp {
    height: 400px;
  }

  .m-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
  }

  &-title {
    @include f-size(40);
    color: #fff;
    @include bpSp {
      @include f-size(30);
    }
    &--en {
      margin-top: 35px;
      @include f-size(14);
      color: #fff;
      @include bpSp {
        margin-top: 10px;
      }
    }
  }

  .m-btn1 {
    margin-top: 35px;
    color: #fff;
    @include bpSp {
      margin-top: 30px;
    }
    &.btn-design {
      border: 1px solid #fff;
      &::before {
        background: #fff;
      }
      &::after {
        background: #fff;
      }

      &:hover {
        color: #000;
      }
    }
  }
}

// hope
.top-hope {
  padding-top: 75px;
  @include bpSp {
    padding-top: 50px;
  }
  &-lead {
    display: flex;
    gap: 40px;
    @include bpSp {
      gap: 0;
    }
    img {
      @include bpSp {
        display: none;
      }
    }
    .textArea {
      display: flex;
      flex-direction: column;

      .title {
        @include f-size(35);
        // 文字高さ調整
        margin-top: -17px;
        @include bpTb {
          @include f-size(24);
        }
      }

      .text {
        margin-top: 40px;
        @include f-size(15);
        @include bpSp {
          margin-top: 20px;
        }
      }

      .m-btn1 {
        margin-top: 45px;
        margin-right: auto;
        margin-left: auto;
        @include bpSp {
          margin-top: 25px;
        }
      }
    }
  }
}

// can 私たちにできること
.top-can {
  margin-top: 75px;
  @include bpSp {
    margin-top: 50px;
  }
  .title {
    position: relative;
    @include f-size(35);
    text-align: center;
    padding-bottom: 26px;
    @include bpTb {
      @include f-size(24);
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 1px;
      border-top: 1px solid #0a6f3a;
    }
  }

  .article-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    gap: 50px;
    @include bpTb {
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      gap: 25px;
    }

    &::before {
      content: "";
      display: block;
      order: 1;
      width: 300px;
      // @include bpTb {
      //   width: 45%;
      // }
    }
  }


  article {
    width: 300px;
    border: 1px solid #0a6f3a;

    // @include bpTb {
    //   width: 45%;
    // }

    // &:nth-child(2) {
    //   margin: 0 20px;
    //   @include bpTb {
    //     margin: 0 0 0 5%;
    //   }
    // }

    .imgArea {
      position: relative;
      img {

      }
      .text {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @include f-size(24);
        color: #fff;
        width: 100%;
        text-align: center;
      }
    }
    .item {
      .items {
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 16px 22px;
          @include f-size(14);
          text-align: center;
          text-decoration: none;
          height: 70px;

          @include bpTb {
              padding: 0;
          }
          &:hover {
            color: #0a6f3a;
            background-color: #edf0ec;
          }
        }
        &.active {
          a {
            background-color: rgba(52, 109, 63, 0.2);

            &:hover {
              color: #0a6f3a;
              background-color: #edf0ec;
            }
          }
        }
      }

    }
  }
}

// active 活動の様子
.top-active {
  margin-top: 75px;
  margin-bottom: 175px;
  @include bpSp {
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .title {
    position: relative;
    @include f-size(35);
    text-align: center;
    padding-bottom: 26px;
    @include bpTb {
      @include f-size(24);
    }

    @include bpSp {
      margin-bottom: 20px;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 1px;
      border-top: 1px solid #0a6f3a;
    }
  }

  .slider-ourActive {
    width: 1000px;
    margin-top: 20px;
    margin-left: 0;
    @include bpPc {
      width: auto;
    }
    @include bpSp {
      margin-top: -20px;
    }
    // -- .active-items
    .active-items {
      width: 375px;
      height: 334px;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      @include bpPcs {
        width: 35.1vw;
        height: 259px;
      }

      @include bpSp {
        display: block;
        width: auto;
        // width: 100%!important;
        height: auto;
      }

      img {
        transform: scale(1);
        transform-origin: bottom;
        margin: auto auto 0;
        height: 189px;
        width: 252px;
        transition: all 1s ease;
        object-fit: cover;

        @include bpPcs {
          width: 27vw;
        }

        @include bpSp {
          width: 100%;
          height: 64vw;
          transition: none;
          transform: none;
        }
      }
    }
    // // .active-items

    // -- .slick-dots
    .slick-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -45px;
      max-width: 100%;
      width: 100%;
      @include bpPc {
        bottom: -37px;
      }
      li {
        width: 15px;
        height: 15px;
        margin: 0 10px;
        border: 1px solid #cfcfcf;
        border-radius: 50%;
        background-color: #fff;
        button {
          width: 100%;
          height: 100%;
          text-align: center;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          &::before {
            content: "";
            line-height: 15px;
            font-family: 'slick';
            font-size: 6px;
            line-height: 20px;
            color: black;
            display: inline-block;
            top: unset;
            left: unset;
            width: 6px;
            height: 6px;
            background-color: #0a6f3a;
            border-radius: 50%;
            opacity: 0;
            position: relative;
          }
        }
      }
      .slick-active {
        // width: 16px;
        // height: 16px;
        button {
          &::before {
            opacity: 1;
          }
        }
      }
    }
    // //.slick-dots

    // -- .slick-active
    .slick-slide.slick-active {
      button {
        &::before {
          opacity: 1;
        }
      }
    }
    // //.slick-active

    // --.slick-center
    .slick-slide.slick-center {
      // margin: 0 -244px;
      img {
        transform: scale(1.76);

        @include bpPcs {
          transform: scale(1.36);
        }

        @include bpSp {
          width: 100%;
          transform: none;
        }
      }

      @include bpSp {
        animation: none;
      }
    }
    // // .slick-center

    // -- .slick-current
      .slick-next,
      .slick-prev {
        top: 68%;
        transform: translate( -50%, 50% );
        z-index: 5;

        @include bpSp {
          top: 50%;
        }
        &:before {
          content: '\f105';
          position: absolute;
          top: 50%;
          left: 50%;
          font-family: "Font Awesome 5 Free", sans-serif;
          font-weight: 900;
          font-size: 14px;
          color: #fff;
          transform: translate(-50%,-50%) rotate(180deg);
          z-index: 1;
          font: normal normal normal 17px/1 FontAwesome;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          background-color: #0a6f3a;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          transform: translate(-50%,-50%) rotate(180deg);
        @include bpSp {
          right: -2%;
          top: 50%;
        }
      }
    }
    .slick-next {
      top: 71%;
      right: 270px;
      transform: rotate(180deg);

      @include bpPcs {
        top: 60%;
        right: 26.7vw;
      }

      @include bpSp {
        top: 49%;
        right: -6px;
      }
    }

    .slick-prev {
      left: 277px;
      
      @include bpPcs {
        top: 57%;
        left: 27.6vw;
      }

      @include bpSp {
        top: 46%;
        left: 3px;
      }
    }
    
  }
}

// お知らせ部分
.top-news {
  position: relative;
  padding: 100px 0 150px;
  @include bpPcs {
    padding: 70px 0 90px;
  }
  @include bpSp {
    padding: 50px 0;
  }
  .top-news-title {
    position: relative;
    .btn-more {
      position: absolute;
      top: 75%;
      right: 15px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      color: $color1;
      @include f-size(13);
      @include bpPcs {
        top: 70%;
      }
      @include bpSp {
        right: -5px;
        @include f-size(12);
      }
      &:hover {
        opacity: 0.9;
        text-decoration: none;
      }
      span {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-left: 10px;
        background-color: $color1;
        border-radius: 50%;
        i {
          position: absolute;
          top: 50%;
          left: 52.5%;
          transform: translate(-50%,-50%);
          color: #fff;
          @include f-size(16);
        }
      }
    }
  }
  .news-custom {
    padding: 70px 0 0;
    @include bpPc {
      padding: 70px 15px 0;
    }
    @include bpPcs {
      padding: 40px 0 0;
    }
    li {
      padding: 30px 0;
      border-bottom: 1px solid #bbbbbb;
      line-height: 1;
      @include bpSp {
        padding: 15px 0;
      }
      &:first-child {
        padding-top: 0;
      }
      a {
        &:hover {
          opacity: 0.9;
          text-decoration: none;
        }
      }
      time {
        font-family: $wFont1;
        @include f-size(13);
        @include bpSp {
          display: block;
          margin-bottom: 5px;
        }
      }
      .new {
        display: inline-block;
        position: relative;
        padding-left: 25px;
        padding-right: 20px;
        color: $color1;
        font-family: $wFont1;
        @include f-size(12);
        @include bpSp {
          @include f-size(10);
          padding-right: 10px;
        }
        &::before {
          content: "";
          position: absolute;
          top: 4px;
          left: 5px;
          transform: translateY(-50%);
          width: 15px;
          height: 1px;
          background-color: $color1;
        }
      }
      .news-title {
        @include bpPcs {
          line-height: 1.8;
        }
        @include bpSp {
          @include f-size(12);
        }
      }
    }
  }
}

// リンク部分
.top-link {
  background: url(../img/bg_footer.jpg) no-repeat center;
  background-size: cover;
  padding: 110px 0;
  @include bpPcs {
    padding: 70px 0;
  }
  .top-link-list {
    display: flex;
    justify-content: center;
    @include bpSp {
      flex-wrap: wrap;
    }
    .m-btn1 {
      display: inline-block;
      margin: 0 25px;
      @include bpSp {
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}

// 20190730 追加
.guideline-box {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  @include bpPcs {
    padding-top: 15px;
  }
  .guideline-btn {
    width: 100%;
    max-width: 165px;
    margin-right: 8px;
    @include bpSps {
      max-width: 145px;
    }
    &:last-child {
      margin-right: 0;
    }
    .title {
      padding-bottom: 8px;
      @include f-size(15);
      line-height: 1;
    }
    .btn {
      position: relative;
      display: inline-block;
      width: 100%;
      background-color: $color1;
      border: 1px solid $color1;
      color: #fff;
      padding: 12px 25px;
      @include f-size(13);
      text-align: center;
      transition: .3s;
      @include bpTb {
        padding: 7px 15px;
        line-height: 1.5em;
      }
      &:hover {
        border: 1px solid #000;
        color: $textColor;
        background-color: transparent;
        text-decoration: none;
      }
      &::after {
        font-family: "FontAwesome";
        content: "\f105";
        font-weight: 400;
        position: absolute;
        top: 21%;
        right: 8px;
        width: 20px;
        height: 20px;
        @include f-size(15);
        @include bpTb {
          top: 31%;
          right: 3px;
          @include f-size(18);
        }
      }
    }
  }
}
